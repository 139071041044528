<template>
    <div class="search_competition_list_wrap">
        <div class="list_title">{{ $t('competition_search') }}
            <div class="list_sub_title"> {{ searchData }} {{ $t('search_result') }}
                <span class="search_cnt">{{ paginationData.total }}</span> {{ $t('amount') }}
            </div>
        </div>
        <ul class="list_item_wrap">
            <li class="list_item" style="cursor: pointer" @click="movePage(`/competition/${data.c_uuid}`)"
                v-for="(data,index) in competitionData" :key="`competition${index}`">
                <div class="thumbnail" :style="` background-image: url(${returnThumbnail(data)});`"></div>
                <div class="category_wrap">
                    <div class="category_title">{{ returnCategoryName(data.cc_idx) }}</div>
                    <div class="wr_subject">{{ data.c_title }}</div>
                    <div class="time_text">
                        {{ data.c_context }}
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import {mapState} from "vuex";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "CompetitionListSearchDetailLayout",
    mixins: [imageOption, imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        competitionData: [],
        paginationData: {},
    },
    data() {
        return {
            itemCategorys: [],
            searchData: "",
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.itemCategorys = this.itemCategoryList
        this.searchData = this.$route.query.ti;
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
        })
    },
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.Poster)) {
                return `${data.Poster[0].org_url}?${this.setImageOptions(278, 387, this.returnExtension(data.Poster[0].org_url))}`;
                // return this.setFitWidthImg(data.Poster, 278);
            }
        },
        returnCategoryName(data) {
            let categoryIndex = this.itemCategorys.findIndex(category => category.node_id === data);
            if (categoryIndex > -1) {
                return this.itemCategorys[categoryIndex].NAME_ENG
            }
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
